import React, { ReactNode } from "react";
import "./base.sass";
import Footer from "./footer";
import NavBar from "./navbar";

const BaseScreen = ({ children }: { children: ReactNode }) => {
  return (
    <div className="font-roboto h-screen">
      <NavBar />
      {children}
      <Footer />
    </div>
  );
};

export default BaseScreen;
