import classNames from "classnames";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import React, { useEffect, useState } from "react";
import { useLocation } from "@reach/router";

const NavBar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  return (
    <nav className="flex py-[10px] items-center">
      <div className="container flex flex-col md:flex-row md:items-center md:h-[80px]">
        <div className="h-[80px] flex items-center justify-between">
          <Link to="/" aria-label="Go to Home page">
            <StaticImage
              src="../images/logo.png"
              alt="README Digital"
              layout="constrained"
              height={40}
              placeholder="tracedSVG"
            ></StaticImage>
          </Link>
          <button className="md:hidden" onClick={() => setIsOpen(!isOpen)}>
            <svg
              className="w-11 h-11 text-gray-600"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
        </div>
        <div
          className={classNames(
            "flex basis-auto flex-grow md:pt-0 md:pb-0",
            isOpen
              ? "pt-[16px] pb-[32px]"
              : "pt-0 pb-0 overflow-hidden md:overflow-visible h-0 md:h-[unset]"
          )}
        >
          <ul className="flex flex-col md:flex-row md:ml-auto gap-x-[42px] gap-y-[8px] text-[20px]">
            <li className="group md:h-[100px] md:inline-flex items-center md:relative">
              <Link
                activeClassName="text-[#F13D6F]"
                className="p-2 pl-0 md:pl-2 hover:text-[#F13D6F]"
                to="/#"
                onClick={(e) => e.preventDefault()}
              >
                Service
              </Link>
              <div className="md:hidden group-hover:block md:absolute md:top-[100px] bg-white min-w-full p-4 pl-0 md:pl-4 md:[z-index:5]">
                <ul className="[white-space:nowrap]">
                  <li className="text-[14px]">
                    <Link
                      activeClassName="text-[#F13D6F]"
                      to="/service/docs-website-building-and-maintenance"
                      className="hover:text-[#F13D6F]"
                    >
                      {"Japanese Docs Site Build & Maintenance"}
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            {/* <li className="inline-flex items-center">
              <Link
                activeClassName="text-[#F13D6F]"
                className="p-2 pl-0 md:pl-2 hover:text-[#F13D6F]"
                to="/pricing"
              >
                Pricing
              </Link>
            </li> */}
            <li className="group md:h-[100px] md:inline-flex items-center md:relative">
              <Link
                activeClassName="text-[#F13D6F]"
                className="p-2 pl-0 md:pl-2 hover:text-[#F13D6F]"
                to="/#"
                onClick={(e) => e.preventDefault()}
              >
                Add-Ons
              </Link>
              <div className="md:hidden group-hover:block md:absolute md:top-[100px] bg-white min-w-full md:min-h-full p-4 pl-0 md:pl-4 md:[z-index:5]">
                <ul className="[white-space:nowrap]">
                  <li className="mb-[24px] text-[14px]">
                    <Link
                      activeClassName="text-[#F13D6F]"
                      to="/add-ons/e-book-publication"
                      className="hover:text-[#F13D6F]"
                    >
                      Japanese eBook Publication Service
                    </Link>
                  </li>
                  <li className="mb-[24px] last:mb-0 text-[14px]">
                    <Link
                      activeClassName="text-[#F13D6F]"
                      to="/add-ons/tutorial-visualization"
                      className="hover:text-[#F13D6F]"
                    >
                      Japanese Tutorial Video Service
                    </Link>
                  </li>
                </ul>
              </div>
            </li>
            <li className="inline-flex items-center">
              <Link
                activeClassName="text-[#F13D6F]"
                className="p-2 pl-0 md:pl-2 hover:text-[#F13D6F]"
                to="/about"
              >
                About
              </Link>
            </li>
            <li className="inline-flex items-center">
              <Link
                to="/contact"
                className="p-2 pl-0 md:pl-2 text-white inline-flex items-center justify-center w-[140px] h-[40px] bg-gradient-to-r from-[#F13D6F] to-[#FF7C01] rounded-[20px]"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
export default NavBar;
