exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-add-ons-e-book-publication-tsx": () => import("./../../../src/pages/add-ons/e-book-publication.tsx" /* webpackChunkName: "component---src-pages-add-ons-e-book-publication-tsx" */),
  "component---src-pages-add-ons-tutorial-visualization-tsx": () => import("./../../../src/pages/add-ons/tutorial-visualization.tsx" /* webpackChunkName: "component---src-pages-add-ons-tutorial-visualization-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cookie-policy-tsx": () => import("./../../../src/pages/cookie-policy.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-service-docs-website-building-and-maintenance-tsx": () => import("./../../../src/pages/service/docs-website-building-and-maintenance.tsx" /* webpackChunkName: "component---src-pages-service-docs-website-building-and-maintenance-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms-and-conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */),
  "component---src-pages-thank-you-tsx": () => import("./../../../src/pages/thank-you.tsx" /* webpackChunkName: "component---src-pages-thank-you-tsx" */)
}

