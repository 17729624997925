import React from "react";
import Logo from "../images/logo-white.png";
import webLogo from "../images/footer-comp/web.svg";
import { Link } from "gatsby";

const Footer = () => (
  <footer className="md:h-[310px]">
    <div className="bg-[url('../images/footer-comp/banner.png')] bg-cover bg-center md:h-[246px] pt-[28px] pb-[10px] md:pb-[28px]">
      <div className="container text-white flex flex-col md:flex-row justify-between gap-y-[20px] md:gap-y-[60px]">
        <div>
          <Link to="/">
            <img src={Logo} className="mb-2 w-[280px]" alt="README Digital" />
          </Link>
          <p className="text-[14px] mb-[20px]">
            Japanese Tier-0 Support Material Service for Your Cloud Product
          </p>
          <a
            target="_blank"
            rel="noreferer"
            className="inline-flex items-center mb-[15px]"
          >
            <img src={webLogo} className="w-[24px] mr-2" alt="Website" /> README Digital Docs Site
          </a>
        </div>
        <div className="hidden md:flex flex-col md:flex-row gap-x-[36px]">
          <div>
            <h2 className="font-bold mb-[30px]">Services</h2>
            <ul>
              <li className="mb-[24px] text-[14px]">
                <Link to="/service/docs-website-building-and-maintenance">
                  {"Japanese Docs Site Build & Maintenance"}
                </Link>
              </li>
              <li className="mb-[24px] text-[14px]">
                <Link to="/add-ons/e-book-publication">[Add-On] Japanese eBook Publication</Link>
              </li>
              <li className="mb-[24px] text-[14px]">
                <Link to="/add-ons/tutorial-visualization">[Add-On] Japanese Tutorial Videos</Link>
              </li>
            </ul>
          </div>
          {/* <div>
            <h2 className="font-bold mb-[30px]">
              <Link to="/pricing">Pricing</Link>
            </h2>
          </div> */}
          <div>
            <h2 className="font-bold mb-[30px]">
              <Link to="/about">About Us</Link>
            </h2>
          </div>
          <div>
            <h2 className="font-bold mb-[30px]">
              <Link to="/contact">Contact Us</Link>
            </h2>
          </div>
        </div>
        <div className="md:hidden">
          <div className="grid grid-cols-2 mb-[10px]">
            <div>
              <h2 className="font-bold mb-[24px]">
                <Link to="/contact">Contact Us</Link>
              </h2>
            </div>
            {/* <div>
              <h2 className="font-bold mb-[24px]">Pricing</h2>
            </div> */}
            <div>
              <h2 className="font-bold mb-[24px]">Services</h2>
            </div>
            <div>
              <h2 className="font-bold mb-[24px]">
                <Link to="/about">About Us</Link>
              </h2>
            </div>
          </div>
          <ul>
            <li className="mb-[24px] text-[14px]">
              <Link to="/service/docs-website-building-and-maintenance">
                {"Japanese Docs Site Build & Maintenance"}
              </Link>
            </li>
            <li className="mb-[24px] text-[14px]">
              <Link to="/add-ons/e-book-publication">[Add-On] Japanese eBook Publication</Link>
            </li>
            <li className="mb-[24px] text-[14px]">
              <Link to="/add-ons/tutorial-visualization">[Add-On] Japanese Tutorial Videos</Link>
            </li>
          </ul>
          <div className="grid grid-cols-2">
            <div>
              <h2 className="font-bold mb-[30ipx]">
                <Link to="/privacy-policy">Prvacy Policy</Link>
              </h2>
            </div>
            <div>
              <h2 className="font-bold mb-[30px]">
                <Link to="/terms-and-conditions">Terms and Conditions</Link>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container flex flex-col md:flex-row justify-between md:items-center md:h-[64px] py-4 md:py-0 text-[13px]">
      <div>Copyright © 2021 Digital Research Corporation</div>
      <div className="hidden md:flex md:flex-row gap-x-[36px] gap-y-2">
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="/terms-and-conditions">Terms and Conditions</Link>
      </div>
    </div>
  </footer>
);

export default Footer;
